var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Actionable avatars"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAction) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Want to trigger the opening of a modal or trigger an action? Set the button prop to instruct")]), _c('code', [_vm._v("<b-avatar>")]), _c('span', [_vm._v(" to render as a ")]), _c('code', [_vm._v("<button>")]), _c('span', [_vm._v(" element.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-avatar', {
    staticClass: "align-baseline",
    attrs: {
      "button": "",
      "variant": "primary",
      "text": "BA"
    },
    on: {
      "click": _vm.showToast
    }
  }), _c('span', [_vm._v(" Button Avatar")]), _c('b-avatar', {
    attrs: {
      "href": "#foobar",
      "src": require('@/assets/images/portrait/small/avatar-s-8.jpg')
    }
  }), _c('span', [_vm._v("Link Avatar")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }