var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Badge Offset"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBadgeOffset) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Use the ")]), _c('code', [_vm._v("badge-offset")]), _c('span', [_vm._v(" prop to control the offset of the badge. ")]), _c('span', [_vm._v("Positive values will move the badge inward, while negative values will move the badge outward.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "badge": "",
      "badge-variant": "success",
      "badge-offset": "-0.5em"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "badge": "",
      "badge-variant": "success",
      "badge-offset": "-2px"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "badge": "",
      "badge-variant": "success",
      "badge-offset": "2px"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "badge": "",
      "badge-top": "",
      "badge-variant": "success",
      "badge-offset": "-0.5em"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }