var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sizes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSizes) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Use the prop")]), _c('code', [_vm._v("size")]), _c('span', [_vm._v(" to specify sizes ")]), _c('code', [_vm._v("sm, md")]), _c('span', [_vm._v("and ")]), _c('code', [_vm._v("lg")]), _c('span', [_vm._v(" default to ")]), _c('code', [_vm._v("1.5em, 2.5em")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("3.5em")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-avatar', {
    attrs: {
      "size": "sm",
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg')
    }
  }), _c('b-avatar', {
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg')
    }
  }), _c('b-avatar', {
    attrs: {
      "size": "lg",
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg')
    }
  }), _c('b-avatar', {
    attrs: {
      "size": "70px",
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }