var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Badge avatars"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBadge) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Easily add a badge to your avatar via the ")]), _c('code', [_vm._v("badge")]), _c('span', [_vm._v(" prop or ")]), _c('code', [_vm._v("'badge'")]), _c('span', [_vm._v(" slot, and the badge variant can be set via the ")]), _c('code', [_vm._v("badge-variant")]), _c('span', [_vm._v(" prop. The badge will scale with the size of the avatar.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-avatar', {
    attrs: {
      "badge": "5",
      "badge-variant": "primary"
    }
  }, [_c('span', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  })], 1)]), _c('b-avatar', {
    attrs: {
      "badge": "3",
      "badge-variant": "primary"
    }
  }), _c('b-avatar', {
    attrs: {
      "badge": "2",
      "badge-variant": "primary"
    }
  }), _c('b-avatar', {
    attrs: {
      "badge": "1",
      "badge-variant": "primary"
    }
  }), _c('b-avatar', {
    attrs: {
      "badge": "10",
      "badge-variant": "primary",
      "text": "BV"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }