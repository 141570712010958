var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Avatar Group"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeGroup) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Group multiple avatars together by wrapping them in a ")]), _c('code', [_vm._v("<b-avatar-group>")]), _c('span', [_vm._v(" component. Add class ")]), _c('code', [_vm._v("pull-up")]), _c('span', [_vm._v(" for pull up the avatar on hover.")])]), _c('b-avatar-group', {
    attrs: {
      "size": "32px"
    }
  }, [_c('b-avatar', {
    staticClass: "pull-up",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-5.jpg')
    }
  }), _c('b-avatar', {
    staticClass: "pull-up",
    attrs: {
      "variant": "primary",
      "src": require('@/assets/images/portrait/small/avatar-s-7.jpg')
    }
  }), _c('b-avatar', {
    staticClass: "pull-up",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-10.jpg')
    }
  }), _c('b-avatar', {
    staticClass: "pull-up",
    attrs: {
      "variant": "danger",
      "src": require('@/assets/images/portrait/small/avatar-s-8.jpg')
    }
  }), _c('b-avatar', {
    staticClass: "pull-up",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }